@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  top: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

nav {
  position: fixed;
  height: 80px;
  z-index: 99;
  width: 100%;
  background: #015660;
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0 20px; /* Padding for left alignment */
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo {
  display: flex;
  align-items: center;
}

.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none; /* Removes underline from logo text */
}


.logo-icon {
  height: 40px;
  margin-right: 12px;
  margin-top: -2px;
}

.wrapper .nav-links {
  display: inline-flex;
  transition: all 0.3s ease;
  margin-left: 20px; /* Adds left margin to nav-links */
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  background: #3A3B3C;
}

.location-icon {
  margin-right: 5px;
}

.nav-links .drop-menu {
  position: absolute;
  background: #015660;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  top: 70px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.mega-box.open {
  top: 70px; /* Adjust this if needed when opened */
  opacity: 1;
  visibility: visible;
}

.mega-box .content {
  background: #015660;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}

.row .mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none; /* Hidden by default */
}

/* Styles for larger devices */
@media screen and (min-width: 970px) {
  .wrapper .btn {
    display: none; /* Hide button on larger screens */
  }

  .nav-links {
    display: inline-flex; /* Keep nav-links visible on larger screens */
  }

  .nav-links .drop-menu,
  .mega-box {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .nav-links li:hover .drop-menu,
  .nav-links li:hover .mega-box {
    opacity: 1;
    visibility: visible;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block; /* Show the menu button on mobile */
  }

  .wrapper .nav-links {
    position: fixed;
    height: calc(100vh - 70px); /* Adjust height to be below the navbar */
    width: 100%;
    max-width: 350px;
    top: 70px; /* Set top to navbar height */
    left: -100%;
    background: #242526;
    display: flex;
    flex-direction: column; /* Stack links vertically */
    padding: 20px 20px; /* Adjust padding for more space */
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  .nav-links.open {
    left: 0; /* Show menu when it's open */
  }

  .nav-links li {
    margin: 15px 0; /* Adjust margin for better spacing */
  }

  .nav-links li a {
    padding: 15px 20px; /* Increased padding for touch targets */
    font-size: 18px; /* Adjusted font size */
  }

  .nav-links .drop-menu,
  .mega-box {
    position: static;
    opacity: 1;
    visibility: visible;
    margin: 10px 0; /* Add margin for dropdowns */
    box-shadow: none; /* Remove shadow for dropdowns */
  }

  /* Responsive adjustments for dropdown menu */
  .drop-menu {
    background: #3A3B3C; /* Change background for visibility */
    border-radius: 5px; /* Add rounded corners */
  }

  .mega-box {
    background: #3A3B3C; /* Consistent styling */
    border-radius: 5px; /* Rounded corners */
    padding: 20px; /* Add padding */
    width: 90%; /* Make it responsive */
    left: 5%; /* Center the mega box */
  }

  .mega-box .content {
    flex-direction: column; /* Stack items vertically */
  }

  .mega-box .content .row {
    width: 100%; /* Full width on mobile */
    line-height: 45px;
    margin-bottom: 15px; /* Space between rows */
  }

  .logo-icon {
    height: 40px; /* Adjust the height as needed */
    margin-right: 8px; /* Space between the logo and the text */
    vertical-align: middle; /* Aligns the logo with the text */
  }

  .menu-icon {
    width: 30px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    filter: brightness(0) invert(1); /* This will make the icon white */
  }
}
