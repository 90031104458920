/* Data.css */

/* General Styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-top: 20px;
}

h3 {
  margin-bottom: 15px;
  text-align: center;
}

/* Timeframe Selector */
.timeframe-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;  /* Centers the items horizontally */
  width: 100%;  /* Ensures it takes full width */
  max-width: 600px;  /* Optional: Limits the width of the selector */
  margin: 20px auto;  /* Centers the container itself */
}

select {
  padding: 8px;
  margin-left: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Chart Container */
.chart-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chart-card {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
}

/* Card Container (Grid Layout) */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card-container .chart-card {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .timeframe-selector,
  .specific-period-selector {
    flex-direction: column;
    align-items: stretch; /* Ensures dropdown takes full width */
  }

  select {
    width: 100%;
    margin-top: 10px;
  }

  .card-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .chart-container.full-width {
    width: 100%;
  }

  .chart-card {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  select {
    font-size: 14px;
  }

  .timeframe-selector {
    margin: 20px;
  }

  .chart-container {
    margin: 15px;
    padding: 15px;
  }
}
