.login-container {
    margin-top: 80px; /* Adjust this value based on your header height */
    padding: 20px; /* Optional: Add padding for better spacing */

/* Reset some default styles and set box-sizing */
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; /* Light gray background for the entire page */
}

/* Forgot Password link styling */
.links {
    margin-top: 10px; /* Space between the password input and the link */
    text-align: right; /* Align to the right */
}

.links .forgot-password {
    text-decoration: none; /* Remove underline */
    color: #007bff; /* Match color to blue */
    font-size: 14px; /* Font size for consistency */
    cursor: pointer; /* Pointer cursor for better UX */
}

.links .forgot-password:hover {
    text-decoration: underline; /* Underline on hover for consistency */
}


/* Centering the login container in the viewport */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    margin-top: 80px; /* Adjust based on header height if needed */
    padding: 20px; /* Optional: Add padding for better spacing */
}

/* Header style for the login form */
h1 {
    margin-bottom: 20px; /* Space below the header */
    text-align: center; /* Center the header text */
    font-size: 16px;
    color: #333; /* Dark gray color for better visibility */
}

/* Main form styling */
.login-form {
    background-color: white; /* White background for the form */
    padding: 20px; /* Padding inside the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 300px; /* Fixed width for the form */
    max-width: 90%; /* Responsive max width */
}

/* Grouping form elements */
.form-group {
    margin-bottom: 20px; /* Increased space for gap between input fields */
}


/* Input field styles */
input[type="email"],
input[type="password"] {
    width: 100%; /* Full width of the container */
    padding: 10px; /* Padding inside the input fields */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px; /* Slightly rounded corners */
    font-size: 14px; /* Font size for the input text */
}

/* Button styles */
.login-button {
    width: 100%; /* Full width of the container */
    padding: 10px; /* Padding inside the button */
    background-color: #2a054e; /* Primary button color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for button text */
}

/* Button hover effect */
.login-button:hover {
    background-color: #2a054e; /* Darker shade on hover for contrast */
}

/* Forgot Password link styling */
.links {
    margin-top: 10px; /* Space between the password input and the link */
    text-align: center; /* Center the link text */
    color: red; /* Change the text color to red */
    font-size: 14px; /* Font size for the link */
}

/* Link styling */
.links a {
    text-decoration: none; /* No underline for links */
    color: red; /* Match color to red */
}

/* Link hover effect */
.links a:hover {
    text-decoration: underline; /* Underline on hover */
}


/* Signup prompt styling */
.signup-prompt {
    margin-top: 10px; /* Space between the button and the signup prompt */
    text-align: center; /* Center the signup prompt text */
}

/* Text styling for signup prompt */
.signup-text {
    color: red; /* Change the text color to red */
    font-size: 14px; /* Font size for the signup prompt */
}

/* Link styling */
a {
    text-decoration: none; /* No underline for links */
    color: #007bff; /* Blue color for links */
    font-size: 14px; /* Font size for links */
}

/* Link hover effect */
a:hover {
    text-decoration: underline; /* Underline on hover for better visibility */
}

}
