.App {
  text-align: center;
  background-color: #282c34; /* Background color */
  min-height: 100vh; /* Minimum height for the app */
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
  font-size: calc(10px + 2vmin); /* Responsive font size */
  color: white; /* Text color */
}

header {
  width: 100%; /* Full width for the header */
  padding: 20px; /* Padding for the header */
  background-color: #3b4047; /* Background color for header */
}

footer {
  width: 100%; /* Full width for the footer */
  padding: 20px; /* Padding for the footer */
  background-color: #3b4047; /* Background color for footer */
  margin-top: 20px; /* Space between the main content and footer */
  display: flex; /* Allow footer content to be in a single row */
  flex-direction: column; /* Stack footer items vertically */
  align-items: center; /* Center align footer items */
}

.main-content {
  margin: 40px 0; /* Adjust top and bottom margin for spacing */
}

.App h1 {
  font-size: 3rem; /* Heading size */
  margin: 20px 0; /* Margin around heading */
}

.App p {
  font-size: 1.5rem; /* Paragraph size */
  margin: 10px 0; /* Margin around paragraph */
}
