/* General Container Styling */
.container {
  max-width: 100%;
  padding: 15px;
  margin: 0 auto;
}

.card {
  border-radius: 10px;
}

.mt-5 {
  margin-top: 3rem !important;
}

/* Modal Styling */
.modal-dialog {
  max-width: 90%;
  margin: 1.75rem auto;
}

.modal-body {
  overflow-y: auto;
  max-height: 80vh;
}

.modal-content {
  border-radius: 10px;
}

/* Form Controls */
.form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 1rem;
}

/* Buttons */
button {
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

button.btn {
  transition: background-color 0.3s ease, color 0.3s ease;
}

button.btn-primary {
  background-color: #007bff;
  color: #fff;
}

button.btn-primary:hover {
  background-color: #0056b3;
  color: #fff;
}

button.btn-success {
  background-color: #28a745;
  color: #fff;
}

button.btn-success:hover {
  background-color: #1e7e34;
  color: #fff;
}

button.btn-outline-primary {
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
}

button.btn-outline-primary:hover {
  background-color: #007bff;
  color: #fff;
}

button.btn-outline-success {
  background-color: #fff;
  color: #28a745;
  border: 1px solid #28a745;
}

button.btn-outline-success:hover {
  background-color: #28a745;
  color: #fff;
}

button.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

button.btn-secondary:hover {
  background-color: #5a6268;
  color: #fff;
}

/* Popup Responsiveness */
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 95%;
  }

  .btn {
    font-size: 0.9rem;
    padding: 10px;
  }

  .card {
    padding: 1rem;
  }
}

/* Alerts */
.alert {
  font-size: 0.9rem;
  border-radius: 5px;
}

/* Miscellaneous */
.text-center {
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}
