/* General Sidebar Styles */
.sidebar {
  width: 250px;
  background-color: #242323;
  color: #fff;
  margin-top: 60px;
  height: 100%;
  transition: width 0.3s;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

/* Collapsed Sidebar */
.sidebar.collapsed {
  width: 80px;
}

/* Menu Item Styles */
.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Left-align content */
  color: #fcfafa !important;
  text-decoration: none;
  padding: 12px;
  font-size: 8px; /* Reduced font size */
  line-height: 1.2; /* Adjust line height */
  width: 100%;
  transition: background-color 0.3s;
  text-align: left; /* Text alignment for smaller font */
}

.nav-link:hover {
  background-color: #555;
  color: #4f3aa9 !important;
}

/* Icon Alignment */
.nav-link i,
.nav-link svg {
  flex-shrink: 0;
  width: 20px; /* Adjusted icon size */
  height: 20px;
  margin-right: 10px;
  font-size: 16px; /* Smaller icon font size */
}

/* Mobile-specific Styles */
@media (max-width: 767px) {
  .nav-link {
    font-size: 8px; /* Reduced font size further for mobile */
    padding: 8px;
  }
}
