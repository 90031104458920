.data-deletion-policy {
    max-width: 800px;
    margin: 30px auto;
    padding: 25px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .data-deletion-policy h1 {
    font-size: 2.2em;
    text-align: center;
    color: #333;
    margin-bottom: 25px;
  }
  
  .data-deletion-policy h2 {
    font-size: 1.6em;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .data-deletion-policy p {
    margin-bottom: 18px;
    font-size: 1.1em;
    color: #444;
  }
  
  .data-deletion-policy ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .data-deletion-policy ul li {
    margin-bottom: 10px;
    font-size: 1.05em;
    color: #555;
  }
  
  .data-deletion-policy a {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px dotted #007bff;
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  .data-deletion-policy a:hover {
    color: #0056b3;
    border-bottom: 1px solid #0056b3;
  }
  
  .data-deletion-policy h1, 
  .data-deletion-policy h2, 
  .data-deletion-policy p,
  .data-deletion-policy ul li {
    transition: color 0.2s;
  }
  
  .data-deletion-policy h1:hover,
  .data-deletion-policy h2:hover,
  .data-deletion-policy p:hover,
  .data-deletion-policy ul li:hover {
    color: #222;
  }
  
  @media (max-width: 768px) {
    .data-deletion-policy {
      padding: 15px;
    }
    .data-deletion-policy h1 {
      font-size: 1.8em;
    }
    .data-deletion-policy h2 {
      font-size: 1.4em;
    }
    .data-deletion-policy p, .data-deletion-policy ul li {
      font-size: 1em;
    }
  }
  