/* Wrapper for the dashboard */
.dashboard-wrapper {
  background-color: white; /* Set the background color to white */
  min-height: 100vh; /* Covers the full height of the viewport */
  width: 100%; /* Ensures the width covers the entire screen */
  display: flex;
  margin-bottom: 20px;
  flex-direction: column; /* Keeps the layout stacked vertically */
}



/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px; /* Default expanded width */
  background-color: #060606;
  color: rgb(252, 252, 252);
  overflow-y: auto;
  transition: width 0.3s ease;
  z-index: 1050;
}

.sidebar.collapsed {
  width: 80px; /* Collapsed width */
}

/* Main content wrapper */
.main-content {
  flex-grow: 1;
  margin-left: 250px; /* Default expanded sidebar */
  transition: margin-left 0.3s ease;
}

.sidebar.collapsed ~ .main-content {
  margin-left: 80px; /* Adjusted for collapsed sidebar */
}


/* Topbar styles */
.top {
  transition: margin-left 0.3s ease;
  margin-left: 255px; /* Default margin for expanded sidebar */
  z-index:999; /* Make sure topbar stays below the sidebar toggle header */
}

.top.collapsed {
  margin-left: 80px; /* Adjusted for collapsed sidebar */
}

/* Sidebar toggle header */
.sidebar-toggle-header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #e8e8e8;
  color: rgb(10, 10, 10);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Match sidebar width */
  z-index: 1000; /* Ensure toggle header stays on top */
  transition: width 0.3s ease;
  margin-bottom: 5px; /* Add 5px gap below the toggle header */
}

/* Collapse sidebar */
.sidebar.collapsed ~ .sidebar-toggle-header {
  width: 80px; /* Match collapsed sidebar width */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sidebar-toggle-header {
    width: 60%; /* Full width on mobile */
    padding: 0 10px; /* Less padding for mobile */
    font-size: 14px; /* Smaller font size on mobile */
    height: 60px; /* Reduced height on mobile */
  }

  .sidebar-toggle-header .some-logo-or-text {
    display: none; /* Optionally hide logo or text on mobile */
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .sidebar-toggle-header {
    font-size: 12px; /* Even smaller font size */
    height: 60px; /* Further reduced height */
  }
}

/* Sidebar title */
.sidebar-title {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.sidebar.collapsed ~ .sidebar-toggle-header .sidebar-title {
  opacity: 0;
  visibility: hidden;
}

/* Sidebar toggle button */
.dashboard-toggle {
  background: none;
  border: none;
  color: rgb(11, 11, 11);
  font-size: 25px;
  margin-left: 5px;
  cursor: pointer;
}

.dashboard-toggle:focus {
  outline: none;
}

/* Dashboard content styling */
.dashboard-content {
  padding-top: 10px; /* To avoid overlap with sidebar-toggle-header */
  background: #f8f9fa;
  border-radius: 8px;
  height: calc(100vh - 20px);
  overflow-y: auto;
}

/* New card styles for 6 cards */
.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px); /* Hover effect for cards */
}

.card-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-body {
  font-size: 16px;
  color: #555;
}

/* Flex or grid layout for the cards */
.dashboard-content .row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-content .col-md-4,
.dashboard-content .col-sm-6 {
  flex: 1 1 30%; /* 3 columns for large screens */
  min-width: 280px; /* Prevents cards from getting too small */
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hidden by default */
  }

  .sidebar.collapsed {
    transform: translateX(0); /* Visible on toggle */
  }

  .main-content {
    margin-left: 0; /* Full-width content on small screens */
  }

  .sidebar.collapsed + .main-content {
    margin-left: 80px; /* Adjust for visible sidebar */
  }

  .dashboard-content .col-md-4 {
    flex: 1 1 100%; /* Full width for smaller screens */
  }

  /* Ensure cards have appropriate gap on smaller screens */
  .dashboard-content .row {
    gap: 15px;
  }
}

/* Adding responsiveness to the topbar */
.top {
  margin-left: 250px; /* Default for expanded sidebar */
}

@media (max-width: 768px) {
  .top {
    margin-left: 80px; /* Adjusted for collapsed sidebar */
  }
}
