/* Base styles for top bar */
.top {
  background-color: #e8e8e8;
  height: 60px;
  line-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-left: 255px; /* Default margin when sidebar is expanded */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: margin-left 0.3s ease; /* Smooth transition for margin change */
}

/* Adjust margin when sidebar is collapsed */
.top.collapsed {
  margin-left: 85px;
}

/* Profile section base styles */
.profile-section {
  display: flex;
  margin-left: 20px;
  align-items: center;
  overflow: hidden; /* Prevents text overflow */
  transition: max-width 0.3s ease;
}

.profile-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.username {
  color: #070707;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block; /* Default state: hidden */
}

/* Show username when top bar is collapsed */
.top.collapsed .username {
  display: inline-block;
}

/* Logout section and confirm dialog */
.logout-section {
  position: relative;
}

.logout-confirm {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  color: #000;
  border: 2px solid #444;
  border-radius: 5px;
  padding: 10px 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  width: 180px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.logout-confirm span {
  font-size: 14px;
  font-weight: bold;
  color: #444;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
}

.confirm-btn,
.cancel-btn {
  background-color: #444;
  color: #fff;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.confirm-btn:hover {
  background-color: #d9534f;
}

.cancel-btn:hover {
  background-color: #5bc0de;
}

/* Mobile specific adjustments */
@media (max-width: 767px) {
  /* Hide profile section when top bar is collapsed */
  .top.collapsed .profile-section {
    display: none;
  }

  /* Default state: Show username when top bar is collapsed */
  .top.collapsed .username {
    display: inline-block;
  }

  /* Show profile section when top bar is expanded */
  .top.expanded .profile-section {
    display: flex;
    max-width: 200px;
  }

  /* Hide username when top bar is expanded */
  .top.expanded .username {
    display: none;
  }

  /* Hide username when toggle header is expanded */
  .toggle-header-expanded .username {
    display: none;
  }

  /* Buttons for mobile devices */
  .confirm-btn {
    background-color: #d9534f; /* Button color for confirm */
  }

  .cancel-btn {
    background-color: #5bc0de; /* Button color for cancel */
  }
}
