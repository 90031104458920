/* Profile.css */

/* Container specific to the Profile component */
.profile-container {
    font-family: Arial, sans-serif;
    margin: auto;
    padding: 20px;
    max-width: 600px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-container h3 {
    text-align: center;
    color: #333;
    margin-top: 20px;
  }
  
  /* Form styling within the profile container */
  .profile-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Labels */
  .profile-container label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  /* Input fields */
  .profile-container input[type="text"],
  .profile-container input[type="file"],
  .profile-container button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
  }
  
  /* Input focus effect */
  .profile-container input[type="text"]:focus,
  .profile-container input[type="file"]:focus,
  .profile-container button:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }
  
  /* Buttons */
  .profile-container button {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: none;
  }
  
  .profile-container button:hover {
    background-color: #0056b3;
  }
  
  .profile-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Image styling */
  .profile-container img {
    display: block;
    margin: 10px auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #ddd;
    object-fit: cover;
  }
  
  /* Cropper container */
  .profile-container .react-easy-crop {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 10px 0;
    background: #f0f0f0;
    border: 1px dashed #ddd;
    border-radius: 8px;
  }
  
  .profile-container p {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin: 10px 0;
  }
  
  /* Success and error messages */
  .profile-container .alert {
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .profile-container .alert.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .profile-container .alert.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  /* Form responsive behavior */
  @media (max-width: 768px) {
    .profile-container {
      padding: 10px;
    }
  
    .profile-container button {
      font-size: 12px;
      padding: 8px;
    }
  }
  