.appointments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  overflow-x: auto;
  width: 100%;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll for smaller screens */
}

.appointments-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px; /* Adjust font size */
  min-width: 1200px; /* Set a minimum width to avoid collapsing columns */
}


.appointments-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  overflow-x: auto; /* Added horizontal scroll for small screens */
}

.appointments-table th,
.appointments-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.appointments-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  margin-left: 10px;

}

.appointments-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.appointments-table tr:hover {
  background-color: #e9e9e9;
  transition: background-color 0.3s ease;
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
}

.patient-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-picture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.action-cell {
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.delete-icon {
  cursor: pointer;
  color: #d9534f;
  font-size: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.delete-icon:hover {
  color: #c9302c;
  transform: scale(1.1);
}

.delete-icon:active {
  transform: scale(1);
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .appointments-container {
    padding: 0 5px;
    margin: 10px 0;
  }

  .appointments-table {
    font-size: 12px; /* Increased font size */
    width: 100%;
    max-width: 100%;
  }

  .appointments-table th,
  .appointments-table td {
    padding: 8px; /* Increased padding for readability */
  }

  .profile-picture {
    width: 30px;
    height: 30px;
  }

  .action-cell {
    font-size: 16px; /* Increased font size for actions */
  }
}

@media (max-width: 480px) {
  .appointments-container {
    padding: 0 5px;
    margin: 5px 0;
  }

  .appointments-table {
    font-size: 14px; /* Increased font size */
    width: 100%;
  }

  .appointments-table th,
  .appointments-table td {
    padding: 8px; /* Increased padding for readability */
  }

  .profile-picture {
    width: 25px;
    height: 25px;
  }

  .action-cell {
    font-size: 16px; /* Increased font size for actions */
  }

  .popup-content {
    padding: 30px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #b8b8b8, -10px -10px 20px #ffffff;
  }

  .popup-content button {
    padding: 10px 20px;
    font-size: 15px;
    border: none;
    border-radius: 22px;
    background: #e0e0e0;
    box-shadow: 5px 5px 15px #b8b8b8, -5px -5px 15px #ffffff;
    color: #007bff;
    font-weight: bold;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }

  .popup-content button:hover {
    box-shadow: inset 5px 5px 15px #b8b8b8, inset -5px -5px 15px #ffffff;
    transform: translateY(-2px);
  }

  .popup-content p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }


  .popup-content {
    padding: 30px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #b8b8b8, -10px -10px 20px #ffffff;
  }

  .popup-content button {
    padding: 10px 20px;
    font-size: 15px;
    border: none;
    border-radius: 22px;
    background: #e0e0e0;
    box-shadow: 5px 5px 15px #b8b8b8, -5px -5px 15px #ffffff;
    color: #007bff;
    font-weight: bold;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }

  .popup-content button:hover {
    box-shadow: inset 5px 5px 15px #b8b8b8, inset -5px -5px 15px #ffffff;
    transform: translateY(-2px);
  }

  .popup-content p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
}
