@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

html, body {
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    height: 100%;
    margin: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

ul {
    list-style: none;
}

.footer {
    background-color: #015660;
    padding: 70px 0;
    width: 100%;
}

.footer-bottom {
    background-color: #0a0a0a;
    text-align: center;
    padding: 10px 0;
    bottom:0;
    color: #ffffff;
    width: 100%;
    font-size: 12px;
}

.footer-col {
    flex: 1 1 200px;
    padding: 0 15px;
    margin-bottom: 30px;
}

.footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-weight: 500;
    position: relative;
    padding-bottom: 2px;
    display: inline-block;
}

.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    background-color: #fdfdfd;
    height: 2px;
    width: 100%;
    max-width: 100%;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #bbbbbb;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer-col .social-links {
    display: flex;
    gap: 8px; /* Space between icons */
    flex-wrap: nowrap; /* Prevent icons from wrapping to a new line */
}

.footer-col .social-links a {
    height: 30px;
    width: 30px;
    line-height: 30px; /* Match line-height to height for vertical centering */
    background-color: rgba(255, 255, 255, 0.2);
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
}

/* Responsive Styles */
@media (min-width: 1024px) {
    .row {
        justify-content: space-between;
    }
    .footer-col {
        flex: 1 1 22%; /* Four columns in one row on large devices */
        margin: 0;
    }
}

@media (max-width: 1024px) {
    .footer-col {
        flex: 1 1 48%; /* Two columns on medium devices */
        margin: 15px 0;
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .footer-col {
        flex: 1 1 100%; /* Full width on small devices */
        margin: 15px 0;
        font-size: 12px;
        text-align: center; /* Center-align text within the column */
    }
    .footer-col .social-links {
        justify-content: center; /* Center-align social icons */
    }
}
