/* PrivacyPolicy.css */

.privacy-policy {
    padding: 100px 20px 20px; /* Top padding to account for header height on large screens */
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .privacy-policy {
        padding-top: 150px; /* Increase top padding to account for fixed header overlap on small screens */
        padding-left: 15px;
        padding-right: 15px;
    }

    .privacy-policy h1 {
        font-size: 2em;
    }

    .privacy-policy h2 {
        font-size: 1.5em;
    }

    .privacy-policy p {
        font-size: 0.95em;
    }
}

.privacy-policy h1 {
    font-size: 2.5em;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy h2 {
    font-size: 1.8em;
    color: #34495e;
    margin-top: 30px;
    margin-bottom: 10px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 5px;
}

.privacy-policy p {
    font-size: 1em;
    color: #555;
    margin-bottom: 15px;
}

.privacy-policy section {
    margin-bottom: 20px;
}

.privacy-policy p, .privacy-policy li {
    padding-left: 15px;
}

.privacy-policy li {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-policy a {
    color: #3498db;
    text-decoration: underline;
}

.privacy-policy a:hover {
    color: #2980b9;
    text-decoration: none;
}
