/* Main container styling */
.home-container {
  max-width: 100%;
  margin: 0 auto;
  padding-top: 80px; /* Space between navbar and content */
  padding-bottom: 20px;
  position: relative; /* Ensure relative positioning for children */
}

/* Slider Styling */
.image-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 1; /* Establish stacking context */
  overflow: hidden; /* Prevent overflow issues with images */
}

/* Dots Styling */
.slick-dots {
  position: relative; /* Ensure dots are positioned at the bottom */
  bottom: 0;
  display: flex;
  justify-content: center; /* Center dots horizontally */
  padding: 20px 0; /* Add spacing around dots */
  z-index: 5; /* Ensure dots appear above slider images */
}

.slick-dots li {
  margin: 0 5px; /* Add margin between dots */
}

.slick-dots li button:before {
  font-size: 12px; /* Customize size of dots */
  color: #fff; /* Default dot color */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Change color of active dot */
}

/* Slider Image Styling */
.slider-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents image overflow during animations */
  z-index: 1; /* Ensure images stay below dots */
}

.slider-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container */
  transition: all 0.3s ease; /* Smooth transition for zoom effects */
}

/* Heading Styling */
h1 {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}
