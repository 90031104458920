/* General Styling for Appointment Container */
.appointment-status {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Row - Flexbox Layout */
.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

/* Date Picker and Center Content Layout */
.date-picker-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.date-picker-left, .center-content, .right-button {
  display: flex;
  align-items: center;
}

.date-picker-left {
  flex: 1;
  justify-content: flex-start;
  min-width: 150px;
}

.center-content {
  flex: 2;
  justify-content: center;
  gap: 20px;
  min-width: 200px;
}

.right-button {
  flex: 1;
  justify-content: flex-end;
  min-width: 100px;
}

/* Responsive Adjustments for Tablets and Smaller Screens */
@media screen and (max-width: 768px) {
  .header-row {
    flex-direction: column;
  }

  .date-picker-row {
    flex-direction: column;
    gap: 15px;
  }

  .center-content {
    flex: none;
    gap: 15px;
  }

  .appointment-stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* Adjust Date Picker Container */
  .date-container {
    padding: 8px 12px;
    font-size: 12px;
  }
}

/* Further Adjustments for Small Mobile Screens */
@media screen and (max-width: 480px) {
  .date-picker-row {
    flex-direction: column;
    gap: 10px;
  }

  .center-content {
    gap: 10px;
  }

  .date-container {
    padding: 6px 10px;
    font-size: 11px;
  }
}

/* Styling for Date Picker Containers */
.date-container {
  background-color: #f8f9fa;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.date-container:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-container .date-text {
  font-size: 14px;
  color: #333;
}

/* Appointment Stats - Flexbox Layout */
.appointment-stats {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  overflow: hidden;
}

.stat-item {
  font-size: 15px;
  color: #555;
  white-space: nowrap;
}

.stat-label {
  font-weight: bold;
  color: #333;
}

.stat-value {
  color: #007bff;
}

/* Responsive Styling for Appointment Stats */
@media screen and (max-width: 768px) {
  .appointment-stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .stat-item {
    flex: 1 1 auto;
    min-width: 60px;
    display: flex;
    flex-direction: column; /* Ensures label is above data */
    align-items: center; /* Centers the content */
  }

  .stat-item .label {
    margin-bottom: 5px; /* Space between label and data */
  }

  .stat-item .data {
    font-weight: 300;
  }
}

@media screen and (max-width: 480px) {
  .appointment-stats {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
  }

  .stat-item {
    flex: 1 1 auto;
    min-width: 60px;
    display: flex;
    flex-direction: column; /* Ensures label is above data */
    align-items: center; /* Centers the content */
  }

  .stat-item .label {
    margin-bottom: 5px;
    font-size: 10px; /* Space between label and data */
  }
}

/* Add Appointment Button Styling */
.add-appointment-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.add-appointment-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Action Row Styling */
.action-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  gap: 10px;
  border: 2px solid #000;
  border-radius: 4px;
  background-color: #fff;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

/* Search Container */
.search-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 100%;
}

/* Search Input */
.search-input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  min-width: 150px;
  box-sizing: border-box;
}

/* Search Button */
.search-btn {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-btn:hover {
  background-color: #0056b3;
}

/* Popup styling */
/* Popup styling */
.search-popup {
  position: absolute;
  top: 50px; /* Adjust according to where you want the popup to appear */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  max-width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it appears on top */
  transition: all 0.3s ease-in-out;
  max-height: 80vh; /* Limit the height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
}

/* Close button */
.search-popup button {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.search-popup button:hover {
  background-color: #d32f2f;
}

/* Title of the popup */
.search-popup h3 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* Table styling for results */
.search-results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.search-results-table th, .search-results-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.search-results-table th {
  background-color: #f2f2f2;
}

.search-results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.search-results-table tr:hover {
  background-color: #f1f1f1;
}

/* No results message */
.search-popup p {
  text-align: center;
  color: #555;
}


/* Download Icon Styling */
.download-icon {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.download-icon:hover {
  color: #000;
}

/* Responsive Search Container Adjustments */
@media screen and (max-width: 768px) {
  .search-container {
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
  }

  .search-input,
  .search-btn {
    width: 100%;
  }

  .download-icon {
    align-self: center;
    font-size: 18px;
  }
}

/* Pagination Styling */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.page-btn:hover {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}

.page-btn.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

/* Responsive Design for Date Picker */
@media (max-width: 768px) {
  .date-picker-row {
    flex-wrap: wrap;
    gap: 10px;
  }

  .date-picker-left,
  .center-content,
  .right-button {
    flex: 1 1 100%;
    text-align: center;
  }

  .add-appointment-btn {
    width: 100%;
  }

  .date-container {
    padding: 8px 12px;
  }

  .appointment-stats {
    gap: 10px;
  }

  .stat-item {
    font-size: 13px;
  }

  .pagination {
    flex-direction: column;
  }
}
