.signup-container {
    margin-top: 80px; /* Adjust this value based on your header height */
    padding: 20px; /* Optional: Add padding for better spacing */

/* Reset some default styles and set box-sizing */
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; /* Light gray background for the entire page */
}

.progress-bar {
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #4caf50, #8bc34a);
    animation: loading 2s linear infinite;
  }
  
  @keyframes loading {
    0% { background-position: 0%; }
    100% { background-position: 100%; }
  }
  


/* Centering the signup container in the viewport */
.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    margin-top: 80px; /* Adjust based on header height if needed */
    padding: 20px; /* Optional: Add padding for better spacing */
}

/* Header style for the signup form */
h1 {
    margin-bottom: 20px; /* Space below the header */
    text-align: center; /* Center the header text */
    font-size: 16px;
    color: #333; /* Dark gray color for better visibility */
}

/* Main form styling */
.signup-form {
    background-color: white; /* White background for the form */
    padding: 20px; /* Padding inside the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 300px; /* Fixed width for the form */
    max-width: 90%; /* Responsive max width */
}

/* Grouping form elements */
.form-group {
    margin-bottom: 20px; /* Increased space for gap between input fields */
}

/* Unified input field styles for all types */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius:   
 4px;
  font-size: 14px;
  box-shadow: none; /* Remove any default shadow */
}
/* Button styles */
.signup-button {
    width: 100%; /* Full width of the container */
    padding: 10px; /* Padding inside the button */
    background-color: #2a054e; /* Primary button color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for button text */
}

/* Button hover effect */
.signup-button:hover {
    background-color: #d81b60; /* Darker shade on hover for contrast */
}

/* Link styling for 'Forgot Password?' */
.links {
    display: flex; /* Use flexbox for alignment */
    justify-content: flex-end; /* Aligns the link to the right */
    margin-top: 5px; /* Space between the password input and the link */
    margin-bottom: 10px;
}

/* Signup prompt styling */
.signup-prompt {
    margin-top: 10px; /* Space between the button and the signup prompt */
    text-align: center; /* Center the signup prompt text */
}

/* Text styling for signup prompt */
.signup-text {
    color: red; /* Change the text color to red */
    font-size: 14px; /* Font size for the signup prompt */
}

/* Link styling */
a {
    text-decoration: none; /* No underline for links */
    color: #007bff; /* Blue color for links */
    font-size: 14px; /* Font size for links */
}

/* Link hover effect */
a:hover {
    text-decoration: underline; /* Underline on hover for better visibility */
}
}